<template>
  <div class="parkingGuide needsclick">
    <ImageEditor
      :close="handleImageEditorClose"
      v-if="showImageEditor"
      :originFile="originFile"
    ></ImageEditor>
    <BackHeader @onBack="handleBack" title="查看停车区指引"></BackHeader>
    <div class="addressInfo">
      <img
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/address.png"
        alt=""
      />
      <div>
        <div>{{ stationName }}</div>
        <div>ID {{ stationId }}</div>
      </div>
    </div>
    <div v-if="noneData && !isEdit" class="noneData">
      <img
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/noneData.png"
        alt=""
      />
      <div>暂无图文指引</div>
      <div>利用图文指引，帮助无人车识别停靠位置，<br />更精准的完成泊车</div>
      <div @click="isEdit = true">上传指引</div>
    </div>
    <div v-if="isEdit || list.length > 0" class="guideCard">
      <div v-if="isEdit" class="uploadTips">
        <span>上传/拍摄图片</span>
        <span>(最多8张)</span>
        <van-popover
          v-model="showPopover"
          trigger="click"
          placement="bottom-start"
          theme="dark"
          get-container=".parkingGuide"
        >
          <span class="tipContent">
            该图片上传后，可帮助无人车识别停靠位置，更精准的完成泊车
          </span>
          <template #reference>
            <img
              class="needsclick"
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/question.png"
              alt=""
            />
          </template>
        </van-popover>
      </div>
      <div v-for="(item, index) in list" :key="item.key" class="uploadListItem">
        <div class="uploadedPic">
          <div v-if="item.loading" class="uploadLoading">
            <img
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/loading.png"
              alt=""
            />
          </div>
          <template v-if="item.url">
            <img
              class="needsclick"
              @click="handleImagePreview(index, item.url)"
              :src="item.url"
              alt=""
            />
          </template>
        </div>
        <div class="description">
          <textarea
            @focus="handleInputFocus"
            @input="handleDescriptionChange($event, index)"
            v-if="isEdit"
            :value="item.description"
            maxlength="20"
            placeholder="可添加文字描述，最多20个字"
          ></textarea>
          <div v-else :class="{ noneDesc: !item.description }">
            {{ item.description || '暂无描述' }}
          </div>
        </div>
        <div v-if="isEdit" class="deleteUploaded">
          <img
            @click="handleDeleteItem(index, item)"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/item_delete.png"
            alt=""
          />
        </div>
      </div>
      <div v-if="isEdit && list.length < 8" class="uploadTrigger">
        <img
          class="needsclick"
          @click="handleUploadClick"
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/uploader.png"
          alt=""
        />
      </div>
    </div>
    <div v-if="isEdit && hideshow" class="bottomBtn submitBtn">
      <div v-if="!changed" class="disabled">提交</div>
      <div v-else @click="submitUpload">提交</div>
    </div>
    <div v-if="!noneData && !isEdit" class="bottomBtn editBtn">
      <div @click="isEdit = true">编辑</div>
    </div>
    <van-uploader
      :before-read="onBeforeRead"
      :after-read="onAfterRead"
      ref="uploader"
      :max-count="1"
      ><span></span
    ></van-uploader>
    <van-image-preview
      v-model="showImagePreview"
      :images="preview.images"
      :startPosition="preview.startPosition"
      :closeable="true"
      closeIconPosition="top-left"
      class="parkingGuide-preview"
      closeIcon="/assets/images/close.png"
    ></van-image-preview>
  </div>
</template>

<script>
import NDialog from '@/components/NDialog';
import BackHeader from '../VehicleTask/components/BackHeader.vue';
import ImageEditor from './ImageEditor.vue';
// import { ImagePreview } from "vant";
import { requestCameraPermission } from '@/utils';
import { selectPictureByPoiId, batchInsertPicture } from '@/api/apiv2';
import { Toast } from 'vant';

export default {
  components: {
    BackHeader,
    ImageEditor,
  },
  data() {
    return {
      showImagePreview: false,
      preview: { images: [], startPosition: 0 },
      showImageEditor: false,
      appBarHeight: `${sessionStorage.getItem('_appBarHeight') ?? 0}px`,
      stationId: null,
      stationName: null,
      noneData: false,
      list: [],
      isEdit: false,
      activeIndex: null,
      uploadFrom: 0, // 0 create; 1 update
      showPopover: false, // 提示
      changed: false, // 是否编辑过
      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
      hideshow: true,
      msg: '',
      uploading: false, // 是否上传中
    };
  },
  watch: {
    showHeight: function () {
      // 奇葩问题
      if (this.docmHeight > this.showHeight + 3) {
        this.hideshow = false;
      } else {
        this.hideshow = true;
      }
    },
    isEdit(newValue) {
      if (newValue) {
        this.stopWatchChange = this.$watch(
          'list',
          () => {
            this.changed = true;
          },
          { deep: true }
        );
      } else {
        this.stopWatchChange();
        this.changed = false;
      }
    },
    list(value) {
      this.noneData = value.length === 0;
    },
  },
  methods: {
    handleImageEditorClose(url = false) {
      if (url) {
        this.list.push({
          url,
          description: '',
          stationId: this.stationId,
          key: performance.now(),
        });
      }
      this.showImageEditor = false;
    },
    handleInputFocus(event) {
      const target = event.target;
      setTimeout(() => {
        target.scrollIntoView(true);
      }, 100);
    },
    async handleBack() {
      if (this.isEdit) {
        // if (this.uploading) {
        //   this.$toast("图片上传中，请稍候");
        //   return;
        // }
        if (this.changed) {
          const result = await NDialog.confirm({
            message: '内容未保存，是否确定退出？',
          });
          if (result === 'confirm') {
            this.getList();
            this.isEdit = false;
          }
        } else {
          this.isEdit = false;
        }
      } else {
        this.$router.back();
      }
    },
    async getList() {
      const list = await selectPictureByPoiId({ id: this.stationId });
      this.list = list;
    },
    handlePopoverClick() {
      this.showPopover = true;
    },
    handleUploadClick() {
      if (process.env.NODE_ENV === 'development') {
        const uploaderIpt =
          this.$refs['uploader'].$el.querySelector('input[type=file]');
        uploaderIpt.click();
      }

      this.activeIndex = this.list.length;
      requestCameraPermission();
    },
    async onAfterRead(file) {
      if (file.file.type.includes('video/')) {
        Toast({
          message: '请上传图片',
          className: 'response-toast',
          forbidClick: true,
        });
        return false;
      }

      this.originFile = file;
      this.showImageEditor = true;
      // this.uploading = true;
    },
    async onBeforeRead() {},
    handleDescriptionChange(event, index) {
      this.$set(this.list[index], 'description', event.target.value);
    },
    handleDeleteItem(index, item) {
      if (item.loading) {
        this.$toast('图片上传中，请稍候');
        return;
      }
      this.list.splice(index, 1);
    },
    async submitUpload() {
      if (this.uploading) {
        this.$toast('图片上传中，请稍候');
        return;
      }
      const result = await NDialog.confirm({
        message: '确定提交停车区指引吗?',
      });
      if (result === 'confirm') {
        await batchInsertPicture({
          poiId: this.stationId,
          poiPictures: this.list.map((item) => ({
            stationId: this.stationId,
            ...item,
            url: item.url.replace(/\?.*/, ''),
          })),
        });
        this.$toast('提交成功');
        this.isEdit = false;
      }
    },
    handleImagePreview(startPosition) {
      this.showImagePreview = true;
      const images = this.list.map((item) => item.url);
      this.preview = { images, startPosition };
      // ImagePreview({
      //   images,
      //   startPosition,
      //   closeable: true,
      //   className: "parkingGuide-preview",
      //   closeIconPosition: "top-left",
      //   closeIcon: "/assets/images/close.png",
      // });
    },
  },
  computed: {},
  created() {
    const { id, name } = this.$route.query;
    this.stationId = id;
    this.stationName = name;
  },
  mounted() {
    const _appBarHeight = sessionStorage.getItem('_appBarHeight');
    document.documentElement.style.setProperty(
      '--safe-top',
      `${_appBarHeight || 0}px`
    );
    window.cameraPermission = () => {
      this.clicked = true;
      const uploaderIpt =
        this.$refs['uploader'].$el.querySelector('input[type=file]');
      uploaderIpt.click();
    };
    this.getList();

    window.addEventListener('resize', () => {
      this.showHeight = document.body.clientHeight;
    });
  },
  beforeDestroy() {
    window.cameraPermission = null;
  },
};
</script>

<style lang="scss">
.parkingGuide-preview {
  .van-image-preview__index {
    top: calc(var(--safe-top) + 16px);
  }
  .van-icon__image {
    margin-top: var(--safe-top);
  }
}
</style>

<style lang="scss" scoped>
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.parkingGuide {
  color: var(--your-variable);
  font-size: initial;
  position: relative;
  height: 100vh;
  .addressInfo {
    background: white;
    display: flex;
    padding: 8px 12px;
    img {
      width: 22px;
      height: 22px;
      margin-right: 2px;
    }
    > div {
      > div {
        &:nth-child(1) {
          font-size: 16px;
          font-weight: 400;
        }
        &:nth-child(2) {
          font-size: 14px;
          font-weight: 400;
          color: rgba(151, 152, 171, 1);
        }
      }
    }
  }

  ::v-deep .van-popover__content {
    padding: 10px;
    width: 176px;
    .tipContent {
      box-sizing: border-box;
      font-size: unset;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: white;
    }
  }

  .noneData {
    padding-top: 90px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 160px;
      height: 160px;
    }
    > div {
      &:nth-child(2) {
        // color: red;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
      &:nth-child(3) {
        text-align: center;
        font-size: 14px;
        margin-top: 4px;
        margin-bottom: 30px;
      }
      &:nth-child(4) {
        width: 158px;
        height: 40px;
        opacity: 1;
        border-radius: 24px;
        background: rgba(67, 110, 255, 1);
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 40px;
        color: white;
      }
    }
  }

  .guideCard {
    box-sizing: border-box;
    margin: 15px;
    padding: 15px;
    background: white;
    border-radius: 12px;
    margin-bottom: 90px;
    .uploadTips {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      > span {
        &:nth-child(1) {
          font-size: 16px;
          font-weight: 400;
        }
        &:nth-child(2) {
          margin-left: 6px;
          font-size: 12px;
          font-weight: 400;
        }
      }
      img {
        width: 14px;
        height: 14px;
        margin-left: 4px;
      }
    }

    .uploadListItem {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .uploadedPic {
        width: 86px;
        height: 86px;
        overflow: hidden;
        margin-right: 6px;
        position: relative;
        flex-shrink: 0;
        img {
          width: 86px;
          height: 86px;
          object-fit: cover;
          border-radius: 6px;
        }
        .uploadLoading {
          position: absolute;
          width: 86px;
          height: 86px;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 22px;
            height: 22px;
            animation: spin 1s infinite linear;
          }
        }
      }

      .description {
        height: 86px;
        margin-right: 6px;
        flex-grow: 1;
        > div {
          word-break: break-word;
        }
        textarea {
          width: 100%;
          height: 86px;
          padding: 8px;
          box-sizing: border-box;
          border-radius: 6px;
          background: rgba(244, 247, 249, 1);
          border: 1px solid rgba(234, 238, 238, 1);
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          resize: none;
          &::placeholder {
            color: rgba(151, 152, 171, 1);
          }
        }
        .noneDesc {
          color: rgba(151, 152, 171, 1);
        }
      }

      .deleteUploaded {
        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    .uploadTrigger {
      margin-top: 10px;
      width: 86px;
      height: 86px;
      img {
        width: 86px;
        height: 86px;
      }
    }
  }

  .bottomBtn {
    background: #f5f5f5;
    position: fixed;
    bottom: 0;
    padding: 24px 24px;
    box-sizing: border-box;
    width: 100%;

    &.submitBtn {
      > div {
        height: 46px;
        line-height: 46px;
        background: rgba(67, 110, 255, 1);
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: white;
        border-radius: 23px;
        &.disabled {
          background: rgba(203, 213, 251, 1);
        }
      }
    }

    &.editBtn {
      > div {
        height: 46px;
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: rgba(67, 110, 255, 1);
        border-radius: 23px;
        border: 2px solid rgba(67, 110, 255, 1);
        box-sizing: border-box;
      }
    }
  }
}
</style>
