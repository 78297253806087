import { fabric } from "fabric";
import Hammer from "hammerjs";
import deleteIcon from "@/assets/imageEditor/delete_img.png";
import scaleIcon from "@/assets/imageEditor/scale.png";
import rotateIcon from "@/assets/imageEditor/rotate.png";

import straight from "@/assets/imageEditor/straight.png";
import turn_l from "@/assets/imageEditor/turn_l.png";
import turn_lf from "@/assets/imageEditor/turn_lf.png";
import turn_r from "@/assets/imageEditor/turn_r.png";
import turn_rf from "@/assets/imageEditor/turn_rf.png";
import parking from "@/assets/imageEditor/parking.png";

const CORNERSIZE = 30; // controls conrner size
const COUNT = 10;
const MAX_SCALE = 2;
const MIN_SCALE = 0.5;

const overlayTypes = {
  0: parking,
  1: turn_l,
  2: turn_lf,
  3: straight,
  4: turn_rf,
  5: turn_r,
};

fabric.Object.prototype.transparentCorners = true;
fabric.Object.prototype.cornerColor = "blue";
fabric.Object.prototype.cornerStyle = "circle";
fabric.Object.prototype.cornerSize = CORNERSIZE;
fabric.Object.prototype.perPixelTargetFind = true;
fabric.Object.prototype.borderColor = "rgba(255, 255, 255, 1)"; // 控制线
fabric.Object.prototype.centeredRotation = false; // 围绕中心旋转
fabric.Object.prototype.centeredScaling = true;

const deleteImg = document.createElement("img");
deleteImg.src = deleteIcon;
const scaleImg = document.createElement("img");
scaleImg.src = scaleIcon;
const rotateImg = document.createElement("img");
rotateImg.src = rotateIcon;
let that;
let canvas;

export const initFabric = function (cvs) {
  let center = null;

  that = this;

  canvas = new fabric.Canvas(cvs, {
    selection: false,
    // evented: false,
    controlsAboveOverlay: true,
    // padding: 50,
    perPixelTargetFind: true, // 启用基于像素的目标查找
    targetFindTolerance: 200, // 调整目标查找的容差范围
    centeredScaling: true,
    centeredRotation: true,
  });

  that.$options.canvas = canvas; // 绑定到组件上

  const hammertime = new Hammer(canvas.upperCanvasEl); //使用hammer 处理gesture

  // hammertime.get("pan").set({ direction: Hammer.DIRECTION_ALL, threshold: 0 });
  // hammertime.get("pinch").set({ enable: true, threshold: 0.1 });
  // hammertime.get("rotate").set({ enable: true, threshold: 0 });
  // hammertime.get("swipe").set({ threshold: 20 });

  // hammertime.get("rotate").recognizeWith("pinch");
  // hammertime.get("pinch").recognizeWith("rotate");

  // hammertime.get("swipe").requireFailure("rotate");
  // hammertime.get("rotate").requireFailure("swipe");

  // canvas.on("mouse:down", function (event) {
  //   console.log(1111);
  //   if (event.target) {
  //     canvas.setActiveObject(event.target);
  //   }
  // });

  // 处理pan卡顿问题
  let isPanInProgress = false;
  let isRotateInProgress = false;
  let isPinchInProgress = false;

  function panCanvas(dx, dy) {
    var viewportTransform = canvas.viewportTransform;
    viewportTransform[4] += dx * 0.1;
    viewportTransform[5] += dy * 0.1;
    canvas.setViewportTransform(viewportTransform);
  }

  // hammertime.on("pan", function (event) {
  //   // 处理平移手势事件
  //   var activeObject = canvas.getActiveObject();
  //   if (!activeObject) {
  //     if (Math.abs(event.deltaX) < 50 && Math.abs(event.deltaY) < 50) return; // 设置一个平移阈值
  //     if (!isPanInProgress) {
  //       isPanInProgress = true;
  //       requestAnimationFrame(function () {
  //         var dx = event.deltaX;
  //         var dy = event.deltaY;
  //         panCanvas(dx, dy);
  //         isPanInProgress = false;
  //       });
  //     }
  //     // activeObject.left += ev.deltaX;
  //     // activeObject.top += ev.deltaY;
  //     // activeObject.setCoords();
  //     // canvas.renderAll();
  //   }
  // });

  var lastScale = 1;
  // var scaleFactor = 0.1; // 缩放因子
  var minScale = 0.25; // 最小缩放比例
  var maxScale = 1; // 最大缩放比例

  function pinchCanvas(event, activeObject) {
    var scale = lastScale * event.scale;

    scale = Math.max(minScale, Math.min(maxScale, scale));
    var diff = scale - lastScale;
    activeObject.scale(lastScale + diff * 0.01);
    lastScale = scale;
    canvas.renderAll();
  }

  hammertime.on("pinch", function (event) {
    // 处理缩放手势事件
    var activeObject = canvas.getActiveObject();
    if (activeObject) {
      if (!isPinchInProgress) {
        isPinchInProgress = true;
        requestAnimationFrame(function () {
          pinchCanvas(event, activeObject);
          isPinchInProgress = false;
        });
      }
    }
  });

  // hammertime.on("pinchend", function () {
  //   canvas.renderAll();
  // });

  // canvas.on("mouse:wheel", (opt) => {
  //   const delta = opt.e.deltaY; // 滚轮，向上滚一下是 -100，向下滚一下是 100
  //   let zoom = canvas.getZoom(); // 获取画布当前缩放值
  //   zoom *= 0.999 ** delta;
  //   if (zoom > 20) zoom = 20; // 限制最大缩放级别
  //   if (zoom < 0.01) zoom = 0.01; // 限制最小缩放级别

  //   // 以鼠标所在位置为原点缩放
  //   canvas.zoomToPoint(
  //     {
  //       // 关键点
  //       x: opt.e.offsetX,
  //       y: opt.e.offsetY,
  //     },
  //     zoom // 传入修改后的缩放级别
  //   );
  // });

  function rotateCanvas(ev, activeObject) {
    var newAngle = activeObject.angle + ev.rotation * 0.01;
    activeObject.angle = newAngle;
    activeObject.setCoords();
    canvas.renderAll();
  }

  var lastRotation = 0;
  var rotationFactor = 0.01; // 旋转因子
  hammertime.on("rotate", function (ev) {
    // 处理旋转手势事件
    var activeObject = canvas.getActiveObject();
    if (activeObject) {
      activeObject.evented = false;

      if (!isRotateInProgress) {
        isRotateInProgress = true;
        requestAnimationFrame(function () {
          rotateCanvas(ev, activeObject);
          isRotateInProgress = false;
        });
      }
    }
  });

  hammertime.on("rotateend", function () {
    canvas.renderAll();
  });

  function deleteObject(eventData, transform) {
    that.$options.overlayCount -= 1;
    const target = transform.target;
    const canvas = target.canvas;
    canvas.remove(target);
    canvas.requestRenderAll();
  }

  // 删除icon
  function renderDeleteIcon(ctx, left, top, styleOverride, fabricObject) {
    const size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(deleteImg, -size / 2, -size / 2, size, size);
    ctx.restore();
  }

  // 放大缩小icon
  function renderScaleIcon(ctx, left, top, styleOverride, fabricObject) {
    const size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(scaleImg, -size / 2, -size / 2, size, size);
    ctx.restore();
  }

  // 旋转icon
  function renderRotateIcon(ctx, left, top, styleOverride, fabricObject) {
    const size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(rotateImg, -size / 2, -size / 2, size, size);
    ctx.restore();
  }

  fabric.Object.prototype.setControlsVisibility({
    tl: false, //top-left
    mt: false, // middle-top
    tr: false, //top-right
    ml: false, //middle-left
    mr: false, //middle-right
    bl: false, // bottom-left
    mb: false, //middle-bottom
    br: true, //bottom-right
    mtr: false, // rotate
  });

  // 修改左下角control
  const brControl = fabric.Object.prototype.controls.br;
  fabric.Object.prototype.controls.br = new fabric.Control({
    x: 0.5,
    y: 0.5,
    offsetX: 0,
    actionHandler: brControl.actionHandler,
    withConnection: false, // 是否有连接线
    render: renderScaleIcon,
    actionName: "scale",
    cornerSize: CORNERSIZE,
    cursorStyle: "pointer",
  });

  // 修改旋转control
  const mtrControl = fabric.Object.prototype.controls.mtr;
  fabric.Object.prototype.controls.mtr = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetX: 0,
    actionHandler: mtrControl.actionHandler,
    withConnection: false, // 是否有连接线
    render: renderRotateIcon,
    actionName: "roate",
    cornerSize: CORNERSIZE,
    cursorStyle: "pointer",
  });

  // 自定义删除control
  fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: -0.5,
    y: -0.5,
    offsetY: 0,
    cursorStyle: "pointer",
    mouseUpHandler: deleteObject,
    render: renderDeleteIcon,
    cornerSize: CORNERSIZE,
    padding: 50,
  });
};

export const drawOverlay = (type = 0) => {
  if (that.$options.overlayCount > COUNT - 1) {
    that.$toast("数量不能超过10个");
    return;
  }
  that.$options.overlayCount += 1;
  fabric.Image.fromURL(overlayTypes[type], function (img) {
    const maxScaleFactor = 1.0; // 最大缩放倍数
    const minScaleFactor = 0.25;
    img.lockScalingFlip = true;
    if (type === 0) {
      img.lockRotation = true;
    }

    img.on("scaling", function () {
      if (img.scaleX > maxScaleFactor || img.scaleY > maxScaleFactor) {
        img.scaleX = maxScaleFactor;
        img.scaleY = maxScaleFactor;
      }
      if (img.scaleX < minScaleFactor || img.scaleY < minScaleFactor) {
        img.scaleX = minScaleFactor;
        img.scaleY = minScaleFactor;
      }
    });

    img.on("selected", () => {
      if (type === 0) {
        img.setControlVisible("mtr", false);
      } else {
        img.setControlVisible("mtr", true);
      }
    });
    img.scale(0.5);
    canvas.add(
      img.set({
        top: canvas.height / 2,
        left: canvas.width / 2,
        originX: "center",
        originY: "center",
      })
    );
    canvas.setActiveObject(img);
  });
};
