<template>
  <div class="imageEditor">
    <div class="imageTopBar">
      <img
        @click="closeImageEditor"
        src="@/assets/imageEditor/close.png"
        alt=""
        class="close"
      />
    </div>
    <div class="imageContent">
      <!-- <input type="file" @change="handleIptChange" /> -->
      <div class="imageBackground needsclick">
        <canvas ref="cvsRef"></canvas>
      </div>
      <div v-if="showTurn" class="imageTools">
        <div
          v-for="(item, index) in directions"
          :key="index"
          @click="handleSelectDirection(index)"
        />
      </div>
    </div>
    <div class="imageToolBar">
      <div>
        <div class="overlayBtn">
          <div @click="turnClick" class="turn">
            <div class="turnIcon" />
            <span>转向</span>
          </div>
          <div @click="parkingClick" class="parking">
            <div class="parkingIcon"></div>
            <span>停靠区</span>
          </div>
        </div>
        <div @click="saveAsImage" class="saveBtn">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { fabric } from "fabric";
import { initFabric, drawOverlay } from "./draw";
import { uploadPicture } from "@/api/apiv2";

export default {
  canvas: null, // fabric对象，由initFabric创建
  overlayCount: 0,
  props: ["close", "originFile"],
  data() {
    return {
      clickable: true, // 保存按钮是否可以点击
      imageLoaded: true,
      showTurn: false,
      directions: [
        {
          direction: "left",
          name: "icon_l",
        },
        {
          direction: "leftFront",
          name: "icon_lf",
        },
        {
          direction: "straight",
          name: "icon_straight",
        },
        {
          direction: "rightFront",
          name: "icon_rf",
        },
        {
          direction: "right",
          name: "icon_r",
        },
      ],
      imgUrl: "",
      originImg: {
        width: 0,
        height: 0,
      }, // 保存图片尺寸，用来设置canvas位置和计算画布位置
    };
  },
  mounted() {
    this.initFabric(this.$refs.cvsRef);
    this.handleReadFile(this.originFile);
  },

  methods: {
    initFabric, // 初始化fabric
    turnClick() {
      this.showTurn = !this.showTurn;
    },
    parkingClick() {
      this.showTurn = false;
      drawOverlay(0);
    },
    async handleReadFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);

      reader.onload = () => {
        // 设置背景图， 固定画布尺寸 1000 pixel
        fabric.Image.fromURL(reader.result, (img) => {
          this.imageLoaded = true;
          const width = window.innerWidth;
          const ratioHeight = window.innerWidth * (img.height / img.width);
          const avaliableHeight = window.innerHeight - 234;
          var height =
            ratioHeight < avaliableHeight ? ratioHeight : avaliableHeight;
          this.$options.canvas.setDimensions({ width: width, height: height });
          // img.scale(cWidth / img.width);
          // this.$options.canvas.setWidth(cWidth);
          // this.$options.canvas.setHeight(img.height * (cWidth / img.width));
          img.set({
            scaleX: this.$options.canvas.width / img.width,
            scaleY: this.$options.canvas.height / img.height,
          });
          this.$options.canvas.setBackgroundImage(img);
          // this.$options.canvas.renderAll();
        });
      };

      reader.onerror = () => {
        this.imageLoaded = true;
        this.$toast("图片加载失败，请退出重试");
      };
    },
    saveAsImage() {
      if (this.clickable) {
        this.clickable = false;
      } else {
        return;
      }
      this.$options.canvas.setZoom(1);
      this.$options.canvas.absolutePan({ x: 0, y: 0 });
      this.$options.canvas.discardActiveObject();
      this.$options.canvas.requestRenderAll();
      this.$loadingCircle.start();
      setTimeout(() => {
        const dataURL = this.$refs.cvsRef.toDataURL();
        const blob = this.dataUrlToBlob(dataURL);
        this.uploadFile(blob);
      }, 500);
    },
    dataUrlToBlob(base64, mimeType = "image/png") {
      let bytes = window.atob(base64.split(",")[1]);
      let ab = new ArrayBuffer(bytes.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeType });
    },
    async uploadFile(blob) {
      const formData = new FormData();
      const file = new File([blob], "parking.png");
      formData.append("file", file);
      const url = await uploadPicture(formData).catch((error) => {
        // 超时可能会引起error
      });
      this.clickable = true;
      this.$loadingCircle.end();
      this.close(url);
    },
    closeImageEditor() {
      this.close();
    },
    // 选中方向图片
    handleSelectDirection(index) {
      drawOverlay(index + 1);
      // this.saveAsImage();
    },
  },
};
</script>

<style lang="scss" scoped>
.imageEditor {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  font-size: initial;

  .imageTopBar {
    height: 84px;
    padding: 0 14px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;

    .close {
      width: 30px;
      height: 30px;
      // margin-bottom: 12px;
    }
  }

  .imageContent {
    flex-grow: 1;
    position: relative;

    .input {
      position: absolute;
      top: 0;
      left: 0;
    }

    .imageBackground {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      :deep(.canvas-container) {
        width: 100vw !important;
        height: fit-content !important;

        canvas {
          width: 100vw !important;
          height: unset !important;
          &.lower-canvas {
            position: static !important;
          }
        }
      }

      img {
        width: 100%;
        object-fit: cover;
      }

      canvas {
        width: 100vw;
        // height: 100%;
      }
    }

    .imageTools {
      width: 100%;
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 50px;
      box-sizing: border-box;

      > div {
        width: 36px;
        height: 36px;
        &:nth-child(1) {
          background: url(../../assets/imageEditor/icon_l.png) center no-repeat;
          background-size: cover;
          &:active {
            background: url(../../assets/imageEditor/icon_l_selected.png) center
              no-repeat;
            background-size: cover;
          }
        }
        &:nth-child(2) {
          background: url(../../assets/imageEditor/icon_lf.png) center no-repeat;
          background-size: cover;
          &:active {
            background: url(../../assets/imageEditor/icon_lf_selected.png)
              center no-repeat;
            background-size: cover;
          }
        }
        &:nth-child(3) {
          background: url(../../assets/imageEditor/icon_straight.png) center
            no-repeat;
          background-size: cover;
          &:active {
            background: url(../../assets/imageEditor/icon_straight_selected.png)
              center no-repeat;
            background-size: cover;
          }
        }
        &:nth-child(4) {
          background: url(../../assets/imageEditor/icon_rf.png) center no-repeat;
          background-size: cover;
          &:active {
            background: url(../../assets/imageEditor/icon_rf_selected.png)
              center no-repeat;
            background-size: cover;
          }
        }
        &:nth-child(5) {
          background: url(../../assets/imageEditor/icon_r.png) center no-repeat;
          background-size: cover;
          &:active {
            background: url(../../assets/imageEditor/icon_r_selected.png) center
              no-repeat;
            background-size: cover;
          }
        }
      }
    }
  }

  .imageToolBar {
    height: 150px;
    padding: 0 32px;
    box-sizing: border-box;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
    }

    img {
      width: 36px;
      height: 36px;
    }

    .overlayBtn {
      width: max-content;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .turnIcon {
          width: 36px;
          height: 36px;
          background: url(../../assets/imageEditor/icon_turn.png) center
            no-repeat;
          background-size: cover;
        }

        .parkingIcon {
          width: 36px;
          height: 36px;
          background: url(../../assets/imageEditor/icon_parking.png) center
            no-repeat;
          background-size: cover;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 20px;
          color: rgba(204, 204, 204, 1);
        }
      }

      .turn {
        margin-right: 18px;
        &:active {
          .turnIcon {
            background: url(../../assets/imageEditor/icon_turn_selected.png)
              center no-repeat;
            background-size: cover;
          }
          span {
            color: rgba(52, 119, 246, 1);
          }
        }
      }

      .parking {
        &:active {
          .parkingIcon {
            background: url(../../assets/imageEditor/icon_parking_selected.png)
              center no-repeat;
            background-size: cover;
          }
          span {
            color: rgba(52, 119, 246, 1);
          }
        }
      }
    }

    .saveBtn {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 42px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      width: 80px;
      height: 42px;
      border-radius: 8px;
      background: rgba(67, 110, 255, 1);
    }
  }
}
</style>
